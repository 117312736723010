import i18next from 'i18next';

import { LanguageMap } from '~/constants/languages';

export const adjustDateByTimezone = (date: string | Date, userTimezone?: string) => {
  const locale = i18next.language as keyof typeof LanguageMap;
  const timeZone = userTimezone || Intl.DateTimeFormat().resolvedOptions().timeZone;

  return new Date((date as unknown as Date).toLocaleString(LanguageMap[locale], { timeZone }));
};

// TODO: Localize this
export const fromISOStringToDate = (isoString: string, userTimezone?: string) => {
  const dateObj = new Date(isoString);

  const tzAdjustedDate = adjustDateByTimezone(dateObj, userTimezone);

  const year = tzAdjustedDate.getFullYear();
  const month = tzAdjustedDate.getMonth() + 1; // Month values start with 0
  const date = tzAdjustedDate.getDate();

  let month_res = '';
  let date_res = '';

  if (month < 10) month_res = '0' + month.toString();
  else month_res = month.toString();

  if (date < 10) date_res = '0' + date.toString();
  else date_res = date.toString();

  return year.toString() + '-' + month_res + '-' + date_res;
};

export const hasTopicLaunched = (startAtIsoString: string, userTimezone?: string) => {
  const nowDate = new Date();
  const tzAdjustedNowDate = adjustDateByTimezone(nowDate, userTimezone);

  const startAtDate = new Date(startAtIsoString);
  const tzAdjustedStartAtDate = adjustDateByTimezone(startAtDate, userTimezone);

  return tzAdjustedNowDate >= tzAdjustedStartAtDate;
};

export const hasTopicEnded = (lockAtIsoString: string, userTimezone?: string) => {
  const nowDate = new Date();
  const tzAdjustedNowDate = adjustDateByTimezone(nowDate, userTimezone);

  const lockAtDate = new Date(lockAtIsoString);
  const tzAdjustedLockAtDate = adjustDateByTimezone(lockAtDate, userTimezone);

  return tzAdjustedNowDate > tzAdjustedLockAtDate;
};

export const reverseDate = (date: string) => {
  const monthAndDate = date.slice(5);
  const abbrYear = date.slice(2, 4);
  return monthAndDate + '-' + abbrYear;
};
